import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Styling/Signup.css";
import DropDownArrow from "../photos/DownArrow.png";
import { Collapse } from "@mui/material";


// custom hook for intersection observer
function useIntersectionObserver(options) {
    // variables
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        }, options);
    
        if (ref.current) {
            observer.observe(ref.current);
        }
    
        return () => {
            if (ref.current) {
            observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);
  
    return [ref, isVisible];
}

// custom component for dropdown arrow
const DropdownArrow = ({ isOpen, onClick }) => {
    const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    return (
        <img
        src={DropDownArrow}
        alt="Dropdown Arrow"
        onClick={onClick}
        style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
            cursor: "pointer",
            width: "7.5px",
            height: "5px",
            filter: isDarkTheme ? "invert(0)" : "invert(1)",
        }}
        />
    );
};

// custom component for FAQ item
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleOpen = () => setIsOpen(!isOpen);
  
    return (
      <div className={`faq-item ${isOpen ? "open" : ""}`}>
        <div className="faq-question sign-up-faq" onClick={toggleOpen}>
          <h2>{question}</h2>
          <DropdownArrow
            className="dropdown-arrow"
            style={{ fill: "white" }}
            isOpen={isOpen}
            onClick={toggleOpen}
          />
        </div>
        <Collapse in={isOpen}>
            <div className="faq-answer sign-up-faq">{answer}</div>
        </Collapse>
        
      </div>
    );
};

const StudentLogin = () => {
    // variables
    const faqs = [
        {
          question: "Is it free to make an account?",
          answer: "Yes. Whether you are a business or a student, it is free to make an account.",
        },
        {
            question: "I'm a business, how do I know I will be happy with the website?",
            answer: "You only pay once you are satisfied with the website. We will work with you to make sure you are happy with the final product!",
        },
        {
            question: "How can I update my profile information?",
            answer: "Once logged in, navigate to your profile settings from the dashboard where you can update your personal information.",
        },
        {
            question: "Who can I contact for support?",
            answer: "Click on the 'Contact Us' link in the footer of the page to send us an email.",
        },
    ];
    const [faqSectionRef, isFaqSectionVisible] = useIntersectionObserver({
        threshold: 0.1,
    });
    const navigate = useNavigate();

    // handle startging the signup process
    const studentStart = () => {
        navigate('/student-signup')
    }
    const businessStart = () => {
        navigate('/business-signup')
    }

    return (
        <div>
            <h1 id='title-of-signup'>Choose your account</h1>
            <h2 className='question'>Are you a student or a business?</h2>
            <div id='two-cols'>
                <div className="column column-for-cards" onClick={studentStart}>
                    <h1 className='heading-for-studentbusiness-card'>Student</h1>
                    <p className='points'>Gain real world experience</p>
                    <p className='points'>Get paid for your skills</p>
                    <p className='points'>Boost your resume</p>
                    <p className='points'>Kickstart Your Career</p>
                    <div id="student-sign-up" className="sign_up_button">Start Here</div>
                </div>
                <div className="column column-for-cards" onClick={businessStart}>
                    <h1 className='heading-for-studentbusiness-card'>Business</h1>
                    <p className="points">Expand your reach</p>
                    <p className="points">Custom website solutions</p>
                    <p className="points">Support local talent</p>
                    <p className="points">Affordable web development</p>
                    <div id="business-sign-up" className="sign_up_button" >Start Here</div>
                </div>
            </div>
            <div className="faq-signup">
                <div
                    ref={faqSectionRef}
                    className={`section-content ${isFaqSectionVisible ? "show" : ""}`}
                >
                    <h2 className="section-title">Frequently Asked Questions</h2>
                    <div className="faq-container">
                        {faqs.map((faq, index) => (
                        <FAQItem
                            key={index}
                            question={faq.question}
                            answer={faq.answer}
                        />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentLogin;