//this is for businesses looking to see the apps they received
import React from "react";
import { collection, query, where, getDocs, orderBy, updateDoc, limit, doc } from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import "../Styling/Applications.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loading from "./Loading";
import { user, db, storage } from "../firebaseInit";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

/*when testing this page, if get uid is null error, sign in first. 
If get map is null, make sure the business even has apps
*/

const MyApplications = () => {
    // variables
    const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const[docData, setDocData] = useState([])
    const[loading, setLoading] = useState(true)
    const[showAlert, setShowAlert] = useState({show: false, row: 0})
    const[statusValue, setStatusValue] = useState([])
    const[selectedAccept, setSelectedAccept] = useState({show: false, row: null})
    const [resumeFile, setResumeFile] = useState([])
    const [showPreview, setShowPreview] = useState({show: false, row: 0});
    const [acceptedALert, setAcceptedALert] = useState(false);
    const businessDocId = useRef(null);

    // get all applications for the business
    useEffect(() => {
        const verifyAuthAndGetApps = async() => {
            if(user){
                const querySnapshotApp = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid)));
                businessDocId.current = querySnapshotApp.docs[0].id;
                const querySnapshotApp2 = await getDocs(query(collection(db, "applications"), where("to", "==", businessDocId.current), where("studentConnected", "==", false), orderBy("from", "asc")));
                const querySnapshotApp3 = await getDocs(query(collection(db, "applications"), where("to", "==", businessDocId.current), where("connectsTo", "==", businessDocId.current), limit(1)));

                // never have duplicate due to the way querys were set up
                const combinedDocs = [...(querySnapshotApp2.docs || []), ...(querySnapshotApp3.docs || [])];
                let studentIDs = combinedDocs.map(doc => doc.data().from);

                //having statusValue done here is important because it must be in the same order as the docData array
                setDocData(combinedDocs.map((doc, index) => {
                    if(doc.data().status === "pending"){
                        setStatusValue(prev => [...prev, 1])
                    } else if (doc.data().status === "accepted"){
                        setStatusValue(prev => [...prev, 2])
                        setSelectedAccept({show: true, row: index});
                        setAcceptedALert(true);
                    } else {
                        setStatusValue(prev => [...prev, 3])
                    }
                    return doc.data()
                }));
                
                // grab a array of resume files from firestore storage
                const listRef = ref(storage, 'Resumes/' + businessDocId.current);
                listAll(listRef)
                .then((result) => {
                    const urlSet = new Set();
                    result.items.forEach((itemRef) => {
                        let resumeID = itemRef.name;
                        getDownloadURL(itemRef)
                        .then((url) => {
                            if(studentIDs.includes(resumeID)) {
                                urlSet.add(url);
                                setResumeFile((Array.from(urlSet)).sort());
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
                setLoading(false)
            }
        }
        verifyAuthAndGetApps();
    }, []);

    // handle changing the status of the application
    const handleChange = async(event, index) => {    
        setStatusValue(prevValue => {
            const copy = [...prevValue];
            copy[index] = event.target.value;
            return copy;
        })

        // update the status of the application
        if(event.target.value === 2) {
            setSelectedAccept({disable: true, row: index});
        } else {
            setSelectedAccept({disable: false, row: index});
        }

        // all applicaitons of the student at index
        const querySnapshot = await getDocs(query(collection(db, "applications"), where('from', '==', docData[index].from)));
        if(querySnapshot.docs.length >= 1) {
            querySnapshot.docs.forEach(async(document) => {
                const docRef = doc(db, "applications", document.id);
                if(document.data().to == businessDocId.current){
                    await updateDoc(docRef, {
                        status: 
                            event.target.value === 1 ? "pending" : event.target.value === 2 ? "accepted" : "rejected",
                        studentConnected: event.target.value === 2 ? true : false,
                        connectsTo: event.target.value === 2 ? businessDocId.current : null
                    });
                } else {
                    if(event.target.value === 2) {
                        await updateDoc(docRef, {
                            status: 
                                event.target.value === 2 ? "Been Accepted" : document.data().status === "pending" ? "pending" : document.data().status === "accepted" ? "accepted" : "rejected",
                            studentConnected: event.target.value === 2 ? true : false,
                            connectsTo: event.target.value === 2 ? businessDocId.current : null
                        });
                    }
                }
            });
            event.target.value === 2 ? setAcceptedALert(true) : setShowAlert({show: true, row: index});
        } 
    };

    // closing alerts
    const handleCloseAlert = () => {
        setShowAlert({show: false, row: 0});
    }

    // handle the accepted alert closing
    const handleCloseAcceptedAlert = () => {
        setAcceptedALert(false);
    }

    return (
        <div>
            <h1>Job Applications</h1>
            <div id="myapplications-page-body">
                {
                    loading ? (
                        <Loading/>
                    ) : docData.length !== 0 ? (
                        <div id="table-container">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Student Full Name</TableCell>
                                            <TableCell align="center">City, State</TableCell>
                                            <TableCell align="center">Language(s) Spoken: </TableCell>
                                            <TableCell align="center">School</TableCell>
                                            <TableCell align="center">Major</TableCell>
                                            <TableCell align="center">Graduation Year</TableCell>
                                            <TableCell align="center">Job Title</TableCell>
                                            <TableCell align="center">Technical Skills</TableCell>
                                            <TableCell align="center">Availible Hours</TableCell>
                                            <TableCell align="center">Why Work For Company</TableCell>
                                            <TableCell align="center">Resume</TableCell>
                                            <TableCell align="center">Accept/Reject</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {docData.map((doc, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow key={index} 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{doc.fullName}</TableCell>
                                                    <TableCell align="center">{doc.cityState}</TableCell>
                                                    <TableCell align="center">{doc.languages}</TableCell>
                                                    <TableCell align="center">{doc.school}</TableCell>
                                                    <TableCell align="center">{doc.major}</TableCell>
                                                    <TableCell align="center">{doc.graduationYear}</TableCell>
                                                    <TableCell align="center">{doc.jobTitle}</TableCell>
                                                    <TableCell align="center">{doc.technicalSkills}</TableCell>
                                                    <TableCell align="center">{doc.availableHours}</TableCell>
                                                    <TableCell align="center">{doc.whyWorkForCompany}</TableCell>
                                                    <TableCell align="center" className="resume-column">
                                                        { showPreview.show && showPreview.row === index ? (
                                                            <VisibilityOffIcon className="resume-buttons preview-icon" onClick={() => setShowPreview({show: false, row: index})}/>
                                                        ) : (
                                                            <VisibilityIcon className="resume-buttons preview-icon" onClick={() => setShowPreview({show: true, row: index})}/>
                                                        )}
                                                            <FileDownloadIcon className="resume-buttons download-icon" onClick={() => window.open(resumeFile[index], '_blank')}/>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Status"
                                                                    value={statusValue[index]}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    disabled={selectedAccept.disable && selectedAccept.row !== index ? true : false}
                                                                >
                                                                    <MenuItem value={1}>Pending</MenuItem>
                                                                    <MenuItem value={2}>Accepted</MenuItem>
                                                                    <MenuItem value={3}>Rejected</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                {showAlert.show && showAlert.row === index && 
                                                    <TableRow>
                                                        <TableCell colSpan={12}>
                                                            <Alert icon={<CheckIcon fontSize="inherit" style={{alignSelf: "center", marginRight: "16px"}}/>}
                                                            severity="success"
                                                            onClose={handleCloseAlert}
                                                            >
                                                                <span className="update-message">
                                                                    <p>Successfully Updated.</p>
                                                                </span>
                                                            </Alert>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {showPreview.show && showPreview.row === index &&
                                                    <TableRow>
                                                        <TableCell colSpan={12}>
                                                            <iframe 
                                                                title="resumePreview" 
                                                                src={`${resumeFile[index]}#toolbar=0&view=Fith`} 
                                                                className="resume-preview-styling" 
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {acceptedALert && (
                                <Alert 
                                icon={
                                    <CheckIcon 
                                    fontSize="inherit" 
                                    sx={{
                                        marginRight: "16px",
                                        marginLeft: "16px",
                                        marginTop: "8px",
                                        '@media (max-width: 350px)': {
                                            marginRight: "0",
                                            marginLeft: "0",
                                            marginTop: "0",
                                        }
                                    }}
                                />}
                                className="accepted-alert"
                                variant="outlined"
                                severity="success"
                                sx={{
                                    backgroundColor: isDarkTheme ? "#08601f" : "#a3f7b9", 
                                    transition: "all 0.5s ease"
                                }}
                                onClose={handleCloseAcceptedAlert}
                                >
                                    <span className="update-message">
                                        <AlertTitle
                                            className="Email-Reachout-Title"
                                            sx={{
                                                fontWeight: "bold", 
                                                textAlign: "left",
                                                '@media (max-width: 350px)': {
                                                    fontSize: "0.8rem"
                                                }
                                            }}
                                        >
                                            Acceptance Sent!
                                        </AlertTitle>
                                        <p className="Email-Reachout-Message">You will receive an email within 48 hours! Please check your email to receive updated information!</p>
                                    </span>
                                </Alert>
                            )}
                        </div>
                    ) : (
                        //no doc data
                        <div className="no-apps-yet">
                            <h2>No Applications Yet!</h2>
                            <p className="text-left">It looks like no students have applied to your business yet. Check back later to see applications as they come in! If you need any help or have questions about the application process, feel free to contact us.</p>
                        </div>
                    )    
                }
            </div>
        </div>
    )
}

export default MyApplications