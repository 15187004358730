import { useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc, getDocs, query, where  } from "firebase/firestore";
import { useState, useEffect } from "react";
import { user, db, storage } from "../firebaseInit";
import { Alert, AlertTitle, Collapse} from '@mui/material';
import { useRef } from "react";
import "../Styling/ApplyForm.css";
import "../Styling/Alert.css";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { ref, uploadBytes } from "firebase/storage";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
import CircularProgress from '@mui/material/CircularProgress';

// icon imports
import {LocationOn} from "@mui/icons-material";
// import {, StarBorder, Star, MoreVert, Business} from "@mui/icons-material";
// business types icons
import { Handshake, BusinessCenter, Storefront, RoomService, Factory, LocalGroceryStore, PrecisionManufacturing} from "@mui/icons-material"
import { MonetizationOn, MedicationLiquid, LocalLibrary, PeopleAlt, House, AirportShuttle, Construction, SportsEsports, Gavel } from "@mui/icons-material";
// import { Icon, IconButton } from "@mui/material";

const ApplyForm = () => {
    // variables
    const param = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showMissingField, setShowMissingField] = useState(false);
    const [resumeNotUploaded, setResumeNotUploaded] = useState(false);
    const [showError, setShowError] = useState(false);
    const [startedSubmit, setStartedSubmit] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);
    const [data, setData] = useState(null);
    const [businessData, setBusinessData] = useState(null);
    const [resume, setResume] = useState(null);
    const auth = getAuth();

    //copied from BusinessProfileCard.js
    // const [starActive, setStarActive] = useState(false);
    // const [showMore, setShowMore] = useState(false);
    const [businessTypeIcon, setBusinessTypeIcon] = useState(null);

    // geting the business data and set icon based on business type
    useEffect(() => {
        const getBusinessData = async() => {
            const docRef = doc(db, 'BusinessProfile', param.busID);
            const docSnap = await getDoc(docRef); 
            setBusinessData(docSnap.data());
            switch (docSnap.data().businessType) {
                case "retail":
                    setBusinessTypeIcon(<Storefront className="icons"/>);
                    break;
                case "service":
                    setBusinessTypeIcon(<RoomService className="icons"/>);
                    break;
                case "manufacturing":
                    setBusinessTypeIcon(<Factory className="icons"/>);
                    break;
                case "wholesale":
                    setBusinessTypeIcon(<LocalGroceryStore className="icons"/>);
                    break;
                case "technology":
                    setBusinessTypeIcon(<PrecisionManufacturing className="icons"/>);
                    break;
                case "finance":
                    setBusinessTypeIcon(<MonetizationOn className="icons"/>);
                    break;
                case "health care":
                    setBusinessTypeIcon(<MedicationLiquid className="icons"/>);
                    break;
                case "education":
                    setBusinessTypeIcon(<LocalLibrary className="icons"/>);
                    break;
                case "hospitality":
                    setBusinessTypeIcon(<PeopleAlt className="icons"/>);
                    break;
                case "real estate":
                    setBusinessTypeIcon(<House className="icons"/>);
                    break;
                case "transportation":
                    setBusinessTypeIcon(<AirportShuttle className="icons"/>);
                    break;
                case "construction":
                    setBusinessTypeIcon(<Construction className="icons"/>);
                    break;
                case "entertainment":
                    setBusinessTypeIcon(<SportsEsports className="icons"/>);
                    break;
                case "legal":
                    setBusinessTypeIcon(<Gavel className="icons"/>);
                    break;
                case "nonprofit":
                    setBusinessTypeIcon(<Handshake className="icons"/>);
                    break;
                case "other":
                    setBusinessTypeIcon(<BusinessCenter className="icons"/>);
                    break;
                default:
                    
            }
        }
        getBusinessData();
    }, [])

    // initialize the data that will be sent to the database hwen student applies to business
    const initializeData = async() => {
        if(user){
            setData({
                // Step 1
                fullName: '',
                cityState: '',
                languages: '',
                // Step 2
                school: '',
                major: '',
                graduationYear: '',
                // Step 3
                technicalSkills: '',
                whyWorkForCompany: '',

                //needed info
                from: user.uid,
                to: param.busID,
                status: 'pending',
                connectsTo: '',
                studentConnected: false,
            })
        }
    }

    // on Auth state change, initialize the data
    useEffect(() => {
        onAuthStateChanged(auth, initializeData);
    }, [])

    // handle change for input fields
    const handleChange = (key, event) => {
        setData( (prevState) => ({
            ...prevState,
            [key]: event.target.value 
        }))
    }

    // check if student applicant already got accepted
    useEffect(() => {
        const handleAcceptedStudentApps = async() => {
            if(user){
                const querySnapshotStudents = await getDocs(query(collection(db, "applications"), where("from", "==", user.uid)));
                let alreayAccepted = false;
                let connectTo = '';
                for (let i = 0; i < querySnapshotStudents.docs.length; i++) {
                    const doc = querySnapshotStudents.docs[i];
                    if (doc.data().status === 'accepted') {
                        alreayAccepted = true;
                        connectTo = doc.data().connectsTo;
                        break; // Exit the loop when the condition is met
                    }
                }

                if (alreayAccepted) {
                    setData(prevData => ({
                        ...prevData,
                        status: 'pending',
                        studentConnected: true,
                        connectsTo: connectTo
                    }));
                }
            }
        }

        handleAcceptedStudentApps();
    }, []);

    // handle submitting the application form
    const submitApplyForm = async() => {
        setStartedSubmit(true);
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "applications"), where("from", "==", user.uid), where("to", "==", param.busID)));
            if(querySnapshot.empty) {
                if(resume){
                    // check if fields are empty
                    if(data.fullName === '' || data.cityState === '' || data.languages === '' || 
                        data.school === '' || data.major === '' || data.graduationYear === '' || 
                        data.technicalSkills === '' || data.whyWorkForCompany === ''){
                        
                        setShowMissingField(true);
                        setStartedSubmit(false);
                    } else {
                        //can't have set then use right after
                        const resumeRef = (ref(storage, 'Resumes/' + param.busID + '/' + user.uid))
                        if(resumeRef){
                            uploadBytes(resumeRef, resume)
                            .then(async (snapshot) => {
                                try{
                                    await addDoc(collection(db, "applications"), data);
                                } catch (error) {
                                    console.error("Error adding document: ", error);
                                }
                            
                                setShowSuccess(true);
                                setDoneLoading(true);
                                setStartedSubmit(false);
                            })
                            .catch((error) => {
                                console.log("hit erorr", error);
                            });
                        } else {
                            console.log("hit resume not being uplodaed")
                        }
                    }
                } else {
                    setResumeNotUploaded(true);
                }
            } else {
                setShowAlert(true);
            }
        } else {
            setShowError(true);
        }
        
    }

    // handle resume change
    const handleResumeChange = (event) => {
        setResume(event.target.files[0]);
    }

    // hangle closing alerts
    const handleCloseAlert = (() => {
        if(showAlert) setShowAlert(false)
        if(showError) setShowError(false)
        if(showMissingField) setShowMissingField(false)
        if(showSuccess) setShowSuccess(false)
        if(resumeNotUploaded) setResumeNotUploaded(false)
    })

    return (
        <div className="form-container">
            <div id="left-and-right-panels">
                <div className="title-container">
                    <h1 id="apply-title" className="title">Apply</h1>
                    {/*loading happens after click submit and while not done loading*/}
                    { (startedSubmit && !doneLoading) &&
                        <CircularProgress className="applyForm-circular-progress"/>
                    }
                    
                    <div id="horizontal-stepper-container">
                        <HorizontalLinearStepper 
                            handleChange={handleChange} 
                            formData={data} 
                            handleResumeChange={handleResumeChange} 
                            resume={resume} 
                            submitApplyForm={submitApplyForm}
                        />
                    </div>
                </div>
                {resumeNotUploaded && (
                    <Collapse className="alert-container-apply" in={resumeNotUploaded}>
                        <Alert className="alert applyAlert" severity="error" variant="outlined" sx={{fontSize: '20px', backgroundColor: "#980005", '@media(prefers-color-scheme: light)' : {backgroundColor: "#f78d8e"}}} onClose={handleCloseAlert}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>No Resume Found!</AlertTitle>
                            Check If You Have Uploaded Your Resume!!
                        </Alert>
                    </Collapse>
                )}
                {showMissingField && (
                    <Collapse className="alert-container-apply" in={showMissingField}>
                        <Alert className="alert applyAlert" severity="error" variant="outlined" sx={{fontSize: '20px', backgroundColor: "#980005", '@media(prefers-color-scheme: light)' : {backgroundColor: "#f78d8e"}}} onClose={handleCloseAlert}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Missing Fields!</AlertTitle>
                            Please Fill Out All Input Fields!
                        </Alert>
                    </Collapse>
                )}
                {showAlert && (
                    <Collapse className="alert-container-apply" in={showAlert}>
                        <Alert className="alert applyAlert" severity="warning" variant="outlined" sx={{fontSize: '20px', backgroundColor: "#ce7600", '@media(prefers-color-scheme: light)' : {backgroundColor: "#ffa134"}}} onClose={handleCloseAlert}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Already Applied!</AlertTitle>
                            You Have Already Applied To This Business!
                        </Alert>
                    </Collapse>
                )}
                {showError && (
                    <Collapse className="alert-container-apply" in={showError}>
                        <Alert className="alert applyAlert" severity="error" variant="outlined" sx={{fontSize: '20px', backgroundColor: "#980005", '@media(prefers-color-scheme: light)' : {backgroundColor: "#f78d8e"}}} onClose={handleCloseAlert}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Error Occurred!</AlertTitle>
                            Something Went Wrong!!! Please Contact OC-Interns If This Issue Persists!
                        </Alert>
                    </Collapse>
                )}
                {showSuccess && (
                    <Collapse className="alert-container-apply" in={showSuccess}>
                        <Alert className="alert applyAlert" severity="success" variant="outlined" sx={{fontSize: '20px', backgroundColor: "#08601f", '@media(prefers-color-scheme: light)' : {backgroundColor: "#a3f7b9"}}} onClose={handleCloseAlert}>
                            <AlertTitle sx={{ fontWeight: 'bold', fontSize: '25px' }}>Application Submitted!</AlertTitle>
                            Application Was Successfully Submitted!
                        </Alert>
                    </Collapse>
                )}
                { businessData ? (
                    //copied from BusinessProfileCard.js explore card
                        <div id="right-panel">
                            <div className="businessType">
                                {businessTypeIcon}
                            </div>
                            <div className="name-and-desc make-grow">
                                <h1> 
                                    {businessData.companyName}
                                </h1>
                                <p id="company-description-apply-form-page">
                                {businessData.companyDescription}
                                </p>
                            </div>
                            <div id="shove-to-bottom">
                                <div className="make-grow">
                                    <div id="location-icon-text">
                                    <span className="right-margin"><LocationOn/></span>
                                    <span>{businessData.companyLocation}</span>
                                    </div>
                                </div>

                                <div className="turn-row make-grow">
                                   <div id="business-type-icon-text">
                                        <p className="right-margin">{businessTypeIcon}</p>
                                        <p >{businessData.businessType}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h1>Loading...</h1>
                    )
                }
            </div>

        </div>
    )
}

export default ApplyForm
