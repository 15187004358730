import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';
import { db } from '../firebaseInit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styling/Verification.css';

const Verification = () => {
   // variables
   const [accountType, SetAccountType] = useState("account Type");
   const [verificationToProfileButton, SetVerificationToProfileButton] = useState("default-verification-to-profile-button");
   const auth = getAuth();
   const navigate = useNavigate();

   // function to check if the user is a student or a business
   const changeTextDescribingUserType = async (newUser) => {
      if (!newUser) {
         console.error("No user provided");
         return;
      }
  
      try {
         // Check if the user is a student or a business
         // structure of the code due to readings from firestore (cost per read)
         const studentQuerySnapshot = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", newUser.uid), limit(1)));
         if (studentQuerySnapshot.docs.length === 1) {
            SetAccountType("Student");
            SetVerificationToProfileButton("verification-to-profile-button-student verification-to-profile-button");
         } else {
            const businessQuerySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", newUser.uid), limit(1)));
            if (businessQuerySnapshot.docs.length === 1) {
               SetAccountType("Business");
               SetVerificationToProfileButton("verification-to-profile-button-business verification-to-profile-button");
            } else {

            }
         }
      } catch (error) {
          console.error("Error querying Firestore: ", error);
      }
   };

   // base on account type navigate to their respective profile page
	const toProfile = () => {
		if(accountType === "Business") {
			navigate("/business-profile");
		} else if(accountType === "Student") {
			navigate("/student-profile");
		} else {
			
		}
	}
   
   // check if the user is a student or a business when the page loads
   useEffect(() => {
      onAuthStateChanged(auth, changeTextDescribingUserType);
   }, []) 

   return (
      <div>
         <div className="verification-message-box">
            <h1 className="email-verification-title">Your Email Has Been Verified</h1>
            <h2 className="welcome-message">Welcome to OC-interns</h2>
            <p className="verification-next-step">Head to the "My Profile" Page to get started right away!</p>
            
            <button className={ verificationToProfileButton } onClick={ toProfile }>Continue to Profile</button>
         </div>
      </div>
   );
};

export default Verification;