import React, {useEffect, useState} from "react";
import { collection, getDocs} from "firebase/firestore"
import BusinessProfileCard from "./BusinessProfileCard";
import "../Styling/ExplorePage.css"
import Loading from "./Loading";
import {db} from "../firebaseInit"
import { query, where } from "firebase/firestore";
import { ConstructionOutlined } from "@mui/icons-material";

const ExplorePage = () => {
    // variables
    const[businessData, setBusinessData] = useState([])
    const[loading, setLoading] = useState(true);   

    // getting business profile data from firebase
    useEffect(() => {
        const getBusinessData = async() => {
            const querySnapshotBusiness = await getDocs(query(collection(db, "BusinessProfile"), where("status", "==", "accepted")));
            //data is just each business profile doc with an id field which is the docs id
            const data = querySnapshotBusiness.docs.map((doc) => {
                let docData = doc.data();
                docData.id = doc.id;
                return docData;
            });
    
            setBusinessData(data);
            setLoading(false);   
        }
        getBusinessData();
    }, [])

    // remove the commas in price and halfing that amount that will be displayed to the students
    const deformatPriceHalfed = (price) => {
        const parts = price.split(".");
        const wholeNumber = parts[0].replace(/,/g, "");
        const decimal = parts[1] !== undefined ? '.' + parts[1]: '';
        const priceFloat = parseFloat(wholeNumber + decimal) / 2;
        return (Math.round(priceFloat * 100)/100).toFixed(2);
    }
    
    return (
        <div >
            <h1>Explore Page</h1>
            <h2> Choose a business right for you</h2>
            <div id="explore-page-body">
            {
                loading ? (
                    <Loading/>
                ) : (
                    businessData.map((business, index) => (
                        <BusinessProfileCard 
                            key={index}
                            price={deformatPriceHalfed(business.price)}
                            busName={business.companyName} 
                            description={business.companyDescription}
                            location={business.companyLocation} 
                            busID={business.id}
                            businessType={business.businessType}
                        />
                    ))
                )
            }
            </div>
        </div>
    )
}

export default ExplorePage;