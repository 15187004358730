import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import '../Styling/ApplyForm.css';
import { WrapText } from '@mui/icons-material';


const steps = ['Personal Information', 'Educational Background', 'Application Details'];

export default function HorizontalLinearStepper({ handleChange, formData, handleResumeChange, resume, submitApplyForm }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [gradYearError, setGradYearError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [errors, setErrors] = useState({
    fullName: false,
    cityState: false,
    languages: false,
    school: false,
    major: false,
    graduationYear: false,
    technicalSkills: false,
    availableHours: false,
    wannaWorkForCompany: false,
  });

  // check if having missing field before going to the next step of application
  const handleNext = () => {
    let hasErrors = false;

    setErrors({
      fullName: false,
      cityState: false,
      languages: false,
      school: false,
      major: false,
      graduationYear: false,
      technicalSkills: false,
      availableHours: false,
      wannaWorkForCompany: false,
    });

    if (activeStep === 0) {
      // Check each field and set error state if necessary
      if (!formData.fullName) {
        setErrors(prevErrors => ({ ...prevErrors, fullName: true }));
        hasErrors = true;
      }
      if (!formData.cityState) {
        setErrors(prevErrors => ({ ...prevErrors, cityState: true }));
        hasErrors = true;
      }
      if (!formData.languages) {
        setErrors(prevErrors => ({ ...prevErrors, languages: true }));
        hasErrors = true;
      }
    } else if (activeStep === 1) {
      if (!formData.school) {
        setErrors(prevErrors => ({ ...prevErrors, school: true }));
        hasErrors = true;
      }
      if (!formData.major) {
        setErrors(prevErrors => ({ ...prevErrors, major: true }));
        hasErrors = true;
      }

      if (!formData.graduationYear || !gradYearError) {
        setErrors(prevErrors => ({ ...prevErrors, graduationYear: true }));
        hasErrors = true;
      }
    } else if (activeStep === 2) {
        if(!formData.wannaWorkForCompany || !formData.technicalSkills || !resume || !formData.availableHours) {
            hasErrors = true;
        }
    } 
    
    if (!hasErrors) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // handle student going back a page to change something
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // handle going back to the explore page
  const handleToExplore = () => {
    navigate('/explore-page');
  }

  // handle submitting the apply form
  const handleSubmit = () => {
    submitApplyForm();
  };

  // handle changing graduation year
  const handleGraduationYearChange = (event) => {
    const value = event.target.value;
    const error = validateGraduationYear(value);
    setGradYearError(error);
    handleChange('graduationYear', event);
  };

  // check if graduation year inputted is valid year and within 10 years in the future
  const validateGraduationYear = (gradYear) => {
    const gradYearNum = parseInt(gradYear);
    const now = new Date();
    const currentYear = now.getFullYear();
    if (gradYearNum >= currentYear && gradYearNum <= currentYear + 10) {
      return true;
    } else {
      return false;
    }
  };

  // handle formData not loading from applyForm yet
  if (!formData) {
    return <Typography>Loading...</Typography>;
  }

  // settings for reviewing form before submit
  const DetailRow = ({ label, value }) => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2,
      width: '100%'
    }}>

    <Typography sx={{ flex: '0 0 auto', textAlign: 'left', whiteSpace: 'nowrap', }}>{label}</Typography>
    <Typography sx={{ flex: '1 1 auto', textAlign: 'right', whiteSpace: 'nowrap' }}>{value}</Typography>  
    </Box>
  );

  return (
    <Box sx={{ width: '100%', margin: 'auto',  }}>
      {!isSmallScreen ? (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 3 }}>
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mb: 2 }}>Review Your Information</Typography>
                <Grid container spacing={0} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%', boxSizing: 'border-box' }}>
                    <Grid item xs={12} sx={{ width: '90%', maxWidth: '90%', margin: '0 auto' }}>
                      <DetailRow label="Full Name:" value={formData.fullName} />
                      <DetailRow label="City, State:" value={formData.cityState} />
                      <DetailRow label="Language(s) Spoken:" value={formData.languages} />
                      <DetailRow label="School:" value={formData.school} />
                      <DetailRow label="Major:" value={formData.major} />
                      <DetailRow label="Graduation Year:" value={formData.graduationYear} />
                      <DetailRow label="Technical Skills:" value={formData.technicalSkills} />
                      <DetailRow label="Available Hours:" value={formData.availableHours} />
                      <DetailRow label="Why Do You Want To Work for This Company:"/>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                      <Typography 
                      variant="body1" 
                      component="div" 
                      sx={{
                        whitespace: 'pre-wrap', 
                        textAlign: 'left',
                      }}
                      >
                        {formData.wannaWorkForCompany} 
                      </Typography>
                    </Box>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Button onClick={handleSubmit}>Submit</Button>
                </Box>
              </>
            ) : (
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
                  {activeStep === 0 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={(event) => handleChange('fullName', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.fullName}
                        sx={{ mb: 2}}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="City, State"
                        name="cityState"
                        value={formData.cityState}
                        onChange={(event) => handleChange('cityState', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.cityState}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Language(s) Spoken"
                        name="languages"
                        value={formData.languages}
                        onChange={(event) => handleChange('languages', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.languages}
                        sx={{ mb: 2 }}
                        required
                      />
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="School"
                        name="School"
                        value={formData.school}
                        onChange={(event) => handleChange('school', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.school}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Major"
                        name="Major"
                        value={formData.major}
                        onChange={(event) => handleChange('major', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.major}
                        sx={{ mb: 2 }}
                        required
                      />
                      <InputMask
                        mask="9999"
                        value={formData.graduationYear}
                        onChange={(event) => handleGraduationYearChange(event)}
                      >
                        {() =>
                          <TextField
                            className="applyFormTextField"
                            label="Graduation Year"
                            name="gradYear"
                            value={formData.graduationYear}
                            fullWidth
                            variant="outlined"
                            error={errors.graduationYear}
                            sx={{ mb: 2 }}
                            required
                          />
                        }
                      </InputMask>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="Technical Skills"
                        name="technicalSkills"
                        value={formData.technicalSkills}
                        onChange={(event) => handleChange('technicalSkills', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.technicalSkills}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Available Hours Per Week"
                        name="Available Hours Per Week"
                        value={formData.availableHours}
                        onChange={(event) => handleChange('availableHours', event)}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={errors.availableHours}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Why Do You Want To Work for This Company"
                        name="Why Do You Want To Work for This Company"
                        value={formData.wannaWorkForCompany}
                        multiline
                        rows={4}
                        onChange={(event) => handleChange('wannaWorkForCompany', event)}
                        fullWidth
                        type="text"
                        variant="outlined"
                        error={errors.wannaWorkForCompany}
                        sx={{ mb: 2 }}
                        required
                      />
                      <label className="resume-label" style={{ textAlign: 'left', marginBottom: '7px' }}>
                        Upload Your Resume
                      </label>
                      <input id="resume" type="file" accept="application/pdf" onChange={handleResumeChange}/>
                      {!resume && (
                        <h6 style={{textAlign: 'left', color: 'red', marginTop: '5px'}}>Please upload your resume</h6>
                      )}
                    </>
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
                  <Button color="inherit" onClick={activeStep == 0 ? handleToExplore: handleBack} sx={{ mr: 1 }}>
                    {activeStep === 0 ? 'Return to Explore Page' : 'Back'}
                  </Button>
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{}}>
            <Stepper activeStep={activeStep} orientation="vertical" sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
              {steps.map((label, index) => (
                <Step key={label} completed={index < activeStep}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          
          <Box sx={{ mt: 6, width: '100%'}}>
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mb: 3 }}>Review Your Information</Typography>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <DetailRow label="Full Name:" value={formData.fullName} />
                    <DetailRow label="City, State:" value={formData.cityState} />
                    <DetailRow label="Language(s) Spoken:" value={formData.languages} />
                    <DetailRow label="School:" value={formData.school} />
                    <DetailRow label="Major:" value={formData.major} />
                    <DetailRow label="Graduation Year:" value={formData.graduationYear} />
                    <DetailRow label="Technical Skills:" value={formData.technicalSkills} />
                    <DetailRow label="Available Hours:" value={formData.availableHours} />
                    <DetailRow label="Why Do You Want To Work for This Company:"/>
                  </Grid>
                  <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                      <Typography 
                      variant="body1" 
                      component="div" 
                      sx={{
                        whitespace: 'pre-wrap', 
                        textAlign: 'left',
                      }}
                      >
                        {formData.wannaWorkForCompany} 
                      </Typography>
                    </Box>
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Button onClick={handleSubmit}>Submit</Button>
                </Box>
              </>
            ) : (
              
              <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, width: '100%'}}>
                {activeStep === 0 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={(event) => handleChange('fullName', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.fullName}
                        sx={{ mb: 2}}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="City, State"
                        name="cityState"
                        value={formData.cityState}
                        onChange={(event) => handleChange('cityState', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.cityState}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Language(s) Spoken"
                        name="languages"
                        value={formData.languages}
                        onChange={(event) => handleChange('languages', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.languages}
                        sx={{ mb: 2 }}
                        required
                      />
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="School"
                        name="School"
                        value={formData.school}
                        onChange={(event) => handleChange('school', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.school}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Major"
                        name="Major"
                        value={formData.major}
                        onChange={(event) => handleChange('major', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.major}
                        sx={{ mb: 2 }}
                        required
                      />
                      <InputMask
                        mask="9999"
                        value={formData.graduationYear}
                        onChange={(event) => handleGraduationYearChange(event)}
                      >
                        {() =>
                          <TextField
                            className="applyFormTextField"
                            label="Graduation Year"
                            name="gradYear"
                            value={formData.graduationYear}
                            fullWidth
                            variant="outlined"
                            error={errors.graduationYear}
                            sx={{ mb: 2 }}
                            required
                          />
                        }
                      </InputMask>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <TextField
                        className="applyFormTextField"
                        label="Technical Skills"
                        name="technicalSkills"
                        value={formData.technicalSkills}
                        onChange={(event) => handleChange('technicalSkills', event)}
                        fullWidth
                        variant="outlined"
                        error={errors.technicalSkills}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Available Hours Per Week"
                        name="Available Hours Per Week"
                        value={formData.availableHours}
                        onChange={(event) => handleChange('availableHours', event)}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={errors.availableHours}
                        sx={{ mb: 2 }}
                        required
                      />
                      <TextField
                        className="applyFormTextField"
                        label="Why Do You Want To Work for This Company"
                        name="Why Do You Want To Work for This Company"
                        value={formData.wannaWorkForCompany}
                        multiline
                        rows={4}
                        onChange={(event) => handleChange('wannaWorkForCompany', event)}
                        fullWidth
                        type="text"
                        variant="outlined"
                        error={errors.wannaWorkForCompany}
                        sx={{ mb: 2 }}
                        required
                      />
                      <label className="resume-label" style={{ textAlign: 'left', marginBottom: '7px' }}>
                        Upload Your Resume
                      </label>
                      <input id="resume" type="file" accept="application/pdf" onChange={handleResumeChange}/>
                      {!resume && (
                        <h6 style={{textAlign: 'left', color: 'red', marginTop: '5px'}}>Please upload your resume</h6>
                      )}
                    </>
                  )}

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
                  <Button color="inherit" onClick={activeStep == 0 ? handleToExplore: handleBack} sx={{ mr: 1 }}>
                    {activeStep === 0 ? 'Return to Explore Page' : 'Back'}
                  </Button>
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
