import React, {useState, useRef } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseInit";
import "../Styling/Login.css";
import { Alert, Collapse, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

const StudentStartSignup = () => {
    // variables
    const [phoneError, setPhoneError] = useState({error: false, message: ""});
    const [emailError, setEmailError] = useState({error: false, message: ""});
    const [passError, setPassError] = useState({error: false, message: ""});
    const [passRetypeError, setPassRetypeError] = useState({error: false, message: ""});
    const emailRef = useRef();
    const passRef = useRef(); 
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const phoneNumberRef = useRef();
    const retypePassRef = useRef();
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    let fullName = firstName.replace(/\s+/g, ' ').trim() + " " + lastName.replace(/\s+/g, ' ').trim();
    const [phoneNumber, setPhoneNumber] = useState("")
    const [retypePass, setRetypePass] = useState("")
    const [alertInfo, SetOpenInfo] = useState(false);
    const inputSX = {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            borderColor: '#ccc',
        },
        width: "100%",
    };
    
    // handle inputs
    const handleEmailChange = (event) => {
        if(event.target.value.length < email.length) {
            setEmailError({error: false, message: ""})
        }
        setEmail(event.target.value)
    }
    const handlePassChange = (event) => {
        if(event.target.value.length < pass.length) {
            setPassError({error: false, message: ""})
        }
        setPass(event.target.value)
    }
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        console.log(fullName)
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        console.log(fullName)
    }
    const handlePhoneNumberChange = (event) => {
        if((event.target.value.replace(/[^0-9]/g, '')).length < phoneNumber.replace(/[^0-9]/g, '').length) {
            setPhoneError({error: false, message: ""})
        }
        setPhoneNumber(event.target.value)
    }
    const handlePasswordRetype = (event) => {
        if(event.target.value.length < retypePass.length) {
            setPassRetypeError({error: false, message: ""})
        }
        setRetypePass(event.target.value)
    }

    // check if phone number is valid
    const isPhoneNumber = (number) => {
        number = number.replace(/[^0-9]/g, '');
        if(number.length !== 10) {
            return false
        }
        return true
    }
    
    // submit signup form
    const signupSubmit = (event) => {
        event.preventDefault();
        const auth = getAuth();

        if(!isPhoneNumber(phoneNumber)){
            setPhoneError({error: true, message: "Invalid phone number"});
            return;
        }

        if(pass !== retypePass) {
            setPassRetypeError({error: true, message: "Passwords do not match"});
            return;
        }

        createUserWithEmailAndPassword(auth, email, pass)
        .then(async(userCredential) => {
            const user = userCredential.user;
            user.name = fullName;

            // actionCodeSettings for email verification
            const actionCodeSettings = {
                url: 'http://ocinterns.com/verification',
                handleCodeInApp: true
            };
            
            // send the email with verification.js link 
            // (make sure to change action link on firebase console when website deployed
            //  from localhost domain to the new domain name)
            await sendEmailVerification(user, actionCodeSettings)
            .then(async() => {
                SetOpenInfo(true);
                await addDoc(collection(db, "StudentProfile"), {
                    uID: user.uid,
                    name: fullName,
                    phoneNumber: phoneNumber,
                    email: email,
                    status: "pending",
                    connectTo: ""
                });
            })
            .catch((error) => {
                console.log(error)
            });

            /*no need for const user = userCredential.user as we are using onAuthStateChanged in firebaseInit.js
            hence by using createUserwithEmailAndPassword it makes onAuthStateChagned get hit and update the user */
        })
        .catch((error) => {
            //failed signup
            const errorCode = error.code;
            const errorMessage = error.message;
            switch(errorCode) {
                case 'auth/invalid-email':
                    setEmailError({error: true, message: "Invalid email"})
                    break
                case 'auth/weak-password':
                    setPassError({error: true, message: "Password is too weak"})
                    break
                case 'auth/email-already-in-use':
                    setEmailError({error: true, message: "Email already in use"})
                    break
                default:
                    setEmailError({error: true, message: errorMessage})
                    break
            }
        });
    }

    return (
        <div className="login-container">
            <h1 id="login-title-text">Student Signup</h1>
            <p className="welcome-message">Start building and earning today!</p>
            <form onSubmit={signupSubmit} className="login-form">
                <TextField
                    type="text"
                    id="name"
                    label="First Name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    required
                    ref={firstNameRef}
                    sx={inputSX}
                    autoComplete="off"
                />
                <TextField
                    type="text"
                    id="name"
                    label="Last Name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    required
                    ref={lastNameRef}
                    sx={inputSX}
                    autoComplete="off"
                />
                <InputMask
                    mask="(999)-999-9999"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                >
                    {() =>
                        <TextField
                            type="text"
                            id="name"
                            label="Phone Number"
                            required
                            error={phoneError.error}
                            helperText={phoneError.error ? phoneError.message : ""}
                            ref={phoneNumberRef}
                            sx={inputSX}
                            autoComplete="off"
                        />
                    }
                </InputMask>
                <TextField
                    type="text"
                    id="Email"
                    label="Email"
                    onChange={handleEmailChange}
                    required
                    error={emailError.error}
                    helperText={emailError.error ? emailError.message : ""}
                    ref={emailRef}
                    sx={inputSX}
                    autoComplete="off"
                />
                <TextField
                    type="password"
                    id="password"
                    label="Password"
                    onChange={handlePassChange}
                    required
                    error={passError.error}
                    helperText={passError.error ? passError.message : ""}
                    ref={passRef}
                    sx={inputSX}
                    autoComplete="off"
                />
                <TextField
                    type="password"
                    id="retype-password"
                    label="Retype Password"
                    onChange={handlePasswordRetype}
                    required
                    error={passRetypeError.error}
                    helperText={passRetypeError.error ? passRetypeError.message : ""}
                    ref={retypePassRef}
                    sx={inputSX}
                    autoComplete="off"
                />
                <Collapse className="alert-container" in={alertInfo}>
                    <Alert className="alert" variant="outlined" severity="info" onClose={() => {SetOpenInfo(false)}}>Please Head to your email to verify your sign up!</Alert>
                </Collapse>
                <button id="student-submit" type="submit">Submit</button>
            </form>
        </div>
    )
}

export default StudentStartSignup;