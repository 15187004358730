import React, {Fragment} from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useRef } from "react";
import "../Styling/Login.css"
import { useNavigate } from "react-router-dom";
import { query, getDocs, collection, where } from "firebase/firestore";
import { db } from "../firebaseInit";
import { Link } from "react-router-dom";
import { VisibilityOff, Visibility} from '@mui/icons-material';
import { TextField, InputAdornment } from "@mui/material";

const Login = () => {
    // variables
    const emailRef = useRef();
    const passRef = useRef();   
    const [emailError, setEmailError] = useState({error: false, message: ""});
    const [passError, setPassError] = useState({error: false, message: ""});
    const[email, setEmail] = useState("");
    const[pass, setPass] = useState("");
    const[hidePass, setHidePass] = useState(true)
    const navigate = useNavigate();
    const inputSX = {
        '& input:-webkit-autofill': {
            'box-shadow': '0 0 0 30px #1b1b1d inset',
            'caret-color': 'grey',
            'background-color': 'transparent',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            borderColor: '#ccc',
        },
        '@media (prefers-color-scheme: light)': {
            '& input:-webkit-autofill': {
                'box-shadow': '0 0 0 30px white inset',
                'caret-color': 'grey',
                'background-color': 'transparent',
            }
        },
        width: "100%",
    }

    // handle changing fields
    const handlePassChange = (event) => {
        if(event.target.value.length < pass.length) {
            setPassError({error: false, message: ""})
        }
        setPass(event.target.value)
    }
    const handleEmailChange = (event) => {
        if(event.target.value.length < email.length) {
            setEmailError({error: false, message: ""})
        }
        setEmail(event.target.value)
    }

    // handle previewing password
    const togglePasswordVisibility = () => {
        setHidePass(!hidePass)
    }

    // handle signing in to the app (student or business)
    const signIn = (event) => {
        event.preventDefault();
        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, pass)
        .then(async(userCredential) => {
            const user = userCredential.user;
            const querySnapshotStudent = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", user.uid)));
            if(querySnapshotStudent.empty) {
                navigate('/my-applications')
            } 
            else {
                navigate('/explore-page')
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            switch(errorCode) {
                case 'auth/invalid-email':
                    setEmailError({error: true, message: "Invalid email"})
                    break
                case 'auth/weak-password':
                    setPassError({error: true, message: "Password is too weak"})
                    break
                case 'auth/email-already-in-use':
                    setEmailError({error: true, message: "Email already in use"})
                    break
                case 'auth/invalid-credential':
                    setPassError({error: true, message: "Incorrect Email or Password"})
                    break
                default:
                    setEmailError({error: true, message: errorMessage})
                    break
            }
        });
    }
    
    return (
        <div className="login-container">
            <h1 id="login-title-text">Login</h1>
            <p className="welcome-message">Enter your email below to log in.</p>
            <form onSubmit={signIn} className="login-form">
                <TextField
                    error={emailError.error}
                    type="text"
                    id="email"
                    label="Email"
                    onChange={handleEmailChange}
                    required
                    ref={emailRef}
                    sx={inputSX}
                    helperText={emailError.error ? emailError.message : ""}
                    autoComplete="username"
                />
                <TextField
                    error={passError.error}
                    type={hidePass ? "password" : "text"}
                    id="password"
                    label="Password"
                    onChange={handlePassChange}
                    required
                    ref={passRef}
                    sx={inputSX}
                    helperText={passError.error ? passError.message : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {hidePass ? 
                                <VisibilityOff onClick={togglePasswordVisibility} sx={{ cursor: 'pointer' }} /> 
                            : 
                                <Visibility onClick={togglePasswordVisibility} sx={{ cursor: 'pointer' }} />
                            }
                        </InputAdornment>,
                    }}
                    autoComplete="current-password"
                />
                <button id="login" type="submit">Submit</button>
                
                <p id="dont-have-account-text">Don't have an account? <Link to={'/signup'} id="no-account-link">Sign up here </Link> </p>
                <p id="dont-have-account-link"><Link to={'/forgot-password'} id="forgot-password-text">Forgot password?</Link></p>
            </form>
        </div>
    )
}
export default Login;