import './App.css';
import "./firebaseInit";
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Home from "./component/Home";
import AboutUs from "./component/AboutUs";
import Signup from './component/Signup';
import Login from './component/Login';
import StudentStartSignup from './component/StudentSignup';
import BusinessStartSignup from './component/BusinessSignup';
import BusinessProfileSetup from './component/BusinessProfile';
import ExplorePage from "./component/ExplorePage"
import Applications from "./component/Applications"
import StudentProfile from "./component/StudentProfile"
import ApplyForm from "./component/ApplyForm"
import logoLight from "./photos/logo-light.png"
import logoDark from "./photos/logo-dark.png"
import MyApplications from './component/MyApplications';
import NotFound from './component/NotFound';
import CheckStudentAuthenticated from './component/ProtectedRoutes/CheckStudentAuthenticated';
import CheckBusinessAuthenticated from './component/ProtectedRoutes/CheckBusinessAuthenticated';
import Admin from './component/Admin';
import CheckAdminAuthenticated from './component/ProtectedRoutes/CheckAdminAuthenticated';
import ForgotPassword from './component/ForgotPassword';
import Verification from './component/Verification';
import Footer from './component/Footer';
import { useEffect, useState, useRef } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db, user, auth } from './firebaseInit';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Close, Menu } from '@mui/icons-material';
import { createTheme, ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';

function App() {
    // variables
    const navigate = useNavigate();
    const location = useLocation()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [currentAccount, setCurrentAccount] = useState("Not signed up yet");
    const [isStudent, setIsStudent] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const isPageRefreshed = useRef(false);
    const [menuOpen, setMenuOpen] = useState(false);

    // detects and set the theme based on the user's system preference
    // (dark mode or light mode)
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    // check if user is student or business
    useEffect(() => {
        //must be in nested func because of async
        const checkIfStudent = async() => {
            //if user is authed, check if they are a student
            //CAREFUL: In the future, we may want to check if the user is a business as well to avoid hard coding, this assumes only two types of users (student and business)
            if(user) {
                const querySnapshot = await getDocs(collection(db, "studentProfile"), where("uID", "==", user.uid), limit(1));
                if(querySnapshot.docs.length == 1) {
                    setIsStudent(true)
                    setIsBusiness(false)
                } else {
                    setIsStudent(false)
                    setIsBusiness(true)
                }
            }
        }
        checkIfStudent();
    }, [])

    // check if user is admin
    useEffect(() => {
        if(user) {
            if(user.uid == "Min5YMYNHFgsLJqNtDkaEbNqppq2") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        } else {
            setIsAdmin(false)
        }
    }, [user])

    // checks if the page is refreshed
    // to avoid force navigation when refreshing the page
    useEffect(() => {
        const navigationEntries = performance.getEntriesByType('navigation');
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
            isPageRefreshed.current = true;
        }
    }, [])

    // changes the current user type based on the user's uid
    // student, business, or no account type not found
    // sets the current account type and navigates to the correct page 
    // based on the user's account type
    const changeTextDescribingUserType = async(newUser) => {
        // structure of the code due to readings from firestore (cost per read)
        if(newUser){
            // remove when merge
            console.log("new user: ", newUser.uid); 
            const studentQuerySnapshot = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", newUser.uid), limit(1)));

            if(studentQuerySnapshot.docs.length === 1 && newUser.emailVerified === true) {
                setCurrentAccount("Student")
                setIsStudent(true)
                setIsBusiness(false)
                if(!isPageRefreshed.current) {
                    navigate('/explore-page');
                }
            } else {
                const businessQuerySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", newUser.uid), limit(1)));

                if(businessQuerySnapshot.docs.length === 1 && newUser.emailVerified === true) {
                    setCurrentAccount("Business");
                    setIsStudent(false)
                    setIsBusiness(true)
                    if(!isPageRefreshed.current) {
                        navigate('/my-applications');
                    }
                } else {
                    console.log("should not reach here - test account not verified");
                }
            }
        } else {
            setCurrentAccount("No account type found")
            setIsStudent(false)
            setIsBusiness(false)
        }

        console.log(currentAccount)
    }

    // hits the auth is changed, but user info hasn't been put into 
    // firestore yet so checking uid will always result in not being there
    useEffect(() => {
        onAuthStateChanged(auth, changeTextDescribingUserType);
        document.getElementsByClassName('App-menu-bar')[0].style.display = 'flex';
    }, []);

    // signes out the user and navigates to the home page
    const signout = async() => {
        signOut(auth).then(() => {
            setCurrentAccount("No account type found")
            setIsBusiness(false)
            setIsStudent(false)
            handleNavLink({preventDefault: () => {}}, false, "home");
        }).catch((error) => {
            console.log("error: ", error)
        });
    }

    // toggles the sidebar menu
    // hides the app-menu-bar when the sidebar is open
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
        // Hide the app-menu-bar when the sidebar is open
        if(menuOpen){
            document.getElementsByClassName('App-menu-bar')[0].style.display = 'flex';
        } else {
            document.getElementsByClassName('App-menu-bar')[0].style.display = 'none';
        }
    };
    
    // conditionally render the sidebar
    // when user zoom in and out of the screen, the sidebar
    // will disappear and render the nav-bar accordingly
    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            const menuBar = document.getElementsByClassName('App-menu-bar')[0];
            if(currentWidth > 1325 && menuOpen){
                setMenuOpen(false);
                menuBar.style.display = 'flex';
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [menuOpen]);

    // closes the sidebar menu when the user clicks outside of the menu
    const handleClickOutside = () => {
        setMenuOpen(false);
        document.getElementsByClassName('App-menu-bar')[0].style.display = 'flex';
    }

    // checks user on the current page and active button on nav-bar / sidebar
    const isActive = (path) => {
        return location.pathname + location.hash === path;
    }

    // Nav bar and Side bar links hide id in url while still specify section to scroll to view
    const handleNavLink = (e, inSideBar, path) => {
        if(inSideBar) {
            handleMenuToggle();
        }
        e.preventDefault();
        navigate("/", {state: {scrollToSection: path}});
    };

    return ( 
        <div>
            <ThemeProvider theme={theme}>
            <CssBaseline />
                <div className="App">
                    <ul className="App-menu-bar">
                
                        {/* "Link" prevents "active" className from popping up.
                        Having NavLink caused logo to underline on homepage */}
                        
                        <li id="first">
                            <Link to="/#home">
                                {theme.palette.mode === 'dark' ? (
                                    <img src={logoLight} alt="logo" id="logo"/>
                                ) : (
                                    <img src={logoDark} alt="logo" id="logo"/>
                                )}
                            </Link>
                        </li>
    
                        {!isStudent && !isBusiness &&
                            <li className='hideOnThin'>
                                {/* NavLink implicitly has a class name of 
                                activeClassName which = active when pressed */}
                                <NavLink to="/#home" end onClick={(e) => handleNavLink(e, false, "home")} className={() => (isActive('/#home') ? 'button active' : 'button')}>
                                    Home
                                </NavLink>
                            </li>
                        }
    
                        {!isStudent && !isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/about" className='button'>
                                    About Us
                                </NavLink>
                            </li>
                        }
    
                        {!isStudent && !isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/#benefitSection" onClick={(e) => handleNavLink(e, false, "benefitSection")} className={() => (isActive('/#benefitSection') ? 'button active' : 'button')}>
                                    Benefits
                                </NavLink>
                            </li>
                        }
    
                        {!isStudent && !isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/#featureSection" onClick={(e) => handleNavLink(e, false, "featureSection")} className={() => (isActive('/#featureSection') ? 'button active' : 'button')}>
                                    Features
                                </NavLink>
                            </li>
                        }
                        
                        {!isStudent && !isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/#faq" onClick={(e) => handleNavLink(e, false, "faq")} className={() => (isActive('/#faq') ? 'button active' : 'button')}>
                                    FAQ
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if user is a student */}
                        { isStudent &&
                            <li className='hideOnThin'>
                                <NavLink to="/explore-page" className='button'>
                                    Explore
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if user is admin */}
                        { isAdmin &&
                            <li className="hideOnThin">
                                <NavLink to="/admin" className='button'>
                                    admin
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if user is a student */}
                        { isStudent &&
                            <li className='hideOnThin'>
                                <NavLink to="/applications" className='button'>
                                    My Applications
                                </NavLink>
                            </li>
                        }
                        
                        {/* render only if user is a business */}
                        { isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/my-applications" className='button'>
                                    Job Applications
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if user is a business */}
                        { isBusiness &&
                            <li className='hideOnThin'>
                                <NavLink to="/business-profile" className='button'>
                                    My Profile
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if user is a student */}
                        { isStudent &&
                            <li className='hideOnThin'>
                                <NavLink to="/student-profile" className='button'>
                                    My Profile
                                </NavLink>
                            </li>
                        }
    
                        {/* render only if neither user is signed in */}
                        { !isStudent && !isBusiness &&
                            <li className="container hideOnThin">
                                <Link to="/login" className='button login-button'>
                                    Login
                                </Link>
                            </li>
                        }
    
                        {/* render only if neither user is signed in */}
                        { !isStudent && !isBusiness &&
                            <li className="container hideOnThin">
                                <Link to="/signup" className='button login-button'>
                                    Sign Up
                                </Link>
                            </li>
                        }
    
                        {/* render only if signed in */}
                        { (isStudent || isBusiness) &&
                            <li className="container hideOnThin">
                                <button onClick={signout} id='signout' >
                                    Sign Out
                                </button>
                            </li>
                        }

                        <div className="menu-icon hideOnWide" >
                            <Menu className="sidebarOpen" onClick={handleMenuToggle}/>
                        </div>
                    </ul>
                
                    {menuOpen && (
                        <ul className='hideOnWide' id="sidebar">
                            {/* "Link" prevents "active" className from popping up.
                            Having NavLink caused logo to underline on homepage */}
                            <div className="burger-icons">
                                { (!isStudent && !isBusiness) &&
                                    <Link to="/#home">
                                        {theme.palette.mode === 'dark' ? (
                                            <img src={logoLight} alt="logo" id="logo"/>
                                        ) : (
                                            <img src={logoDark} alt="logo" id="logo"/>
                                        )}
                                    </Link>
                                }
                                <Close className="sidebar-close" onClick={handleMenuToggle}/>
                            </div>

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    {/* NavLink implicitly has a class name of
                                    activeClassName which = active when pressed */}
                                    <NavLink to="/#home" end onClick={(e) => handleNavLink(e, true, "home")} className={() => (isActive('/#home') ? 'button active' : 'button')}>
                                        Home
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/about" className='button' onClick={handleMenuToggle}>
                                        About Us
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#benefitSection" onClick={(e) => handleNavLink(e, true, "benefitSection")} className={() => (isActive('/#benefitSection') ? 'button active' : 'button')}>
                                        Benefits
                                    </NavLink>
                                </li>
                            }

                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#featureSection" onClick={(e) => handleNavLink(e, true, "featureSection")} className={() => (isActive('/#featureSection') ? 'button active' : 'button')}>
                                        Features
                                    </NavLink>
                                </li>
                            }
                            
                            {!isStudent && !isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/#faq" onClick={(e) => handleNavLink(e, true, "faq")} className={() => (isActive('/#faq') ? 'button active' : 'button')}>
                                        FAQ
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/explore-page" className='button' onClick={handleMenuToggle}>
                                        Explore
                                    </NavLink>
                                </li>
                            }
                            
                            { isAdmin &&
                                <li className="hideOnWide">
                                    <NavLink to="/admin" className='button'>
                                        admin
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/applications" className='button' onClick={handleMenuToggle}>
                                        My Applications
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a student */}
                            { isStudent &&
                                <li className='hideOnWide'>
                                    <NavLink to="/student-profile" className='button' onClick={handleMenuToggle}>
                                        My Profile
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a business */}
                            { isBusiness &&
                                <li className='hideOnWide'>
                                    <NavLink to="/my-applications" className='button' onClick={handleMenuToggle}>
                                        Job Applications
                                    </NavLink>
                                </li>
                            }

                            {/* render only if user is a business */}
                            { isBusiness &&
                                <li className='hideOnWide' >
                                    <NavLink to="/business-profile" className='button' onClick={handleMenuToggle}>
                                        My Profile
                                    </NavLink>
                                </li>
                            }

                            {/* render only if neither user is signed in */}
                            { !isStudent && !isBusiness &&
                                <li className="container hideOnWide">
                                    <Link to="/login" className='button login-button' onClick={handleMenuToggle}>
                                        Login
                                    </Link>
                                </li>
                            }

                            {/* render only if neither user is signed in */}
                            { !isStudent && !isBusiness &&
                                <li className="container hideOnWide ">
                                    <Link to="/signup" className='button login-button' onClick={handleMenuToggle}>
                                        Sign Up
                                    </Link>
                                </li>
                            }

                            {/* render only if signed in */}
                            { (isStudent || isBusiness) &&
                                <li className="container hideOnWide">
                                    <button onClick={signout} id='signout'>
                                        Sign Out
                                    </button>
                                </li>
                            }
                        </ul>
                    )}

                    <div className="app-body" onClick={handleClickOutside}>
                        <Routes>
                            <Route
                                path="/"
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/about"
                                element={<AboutUs />}
                            ></Route>
                            <Route
                                path="/login"
                                element={<Login />}
                            ></Route>
                            <Route
                                path="/signup"
                                element={<Signup />}
                            ></Route>
                            <Route
                                path="/student-signup"
                                element={<StudentStartSignup/>}
                            ></Route>
                            <Route
                                path="/business-signup"
                                element={<BusinessStartSignup/>}
                            ></Route>
                            <Route
                                path="/business-profile"
                                element={<BusinessProfileSetup/>}
                            ></Route>
                            {/* explore page for students */}
                            <Route
                                path="/explore-page"
                                element={<CheckStudentAuthenticated ComponentWithAuth={ExplorePage}/>}
                            ></Route>
                            <Route
                                path="/applications"
                                element={<CheckStudentAuthenticated ComponentWithAuth={Applications}/>}
                            ></Route>
                            {/* : indicates dynamic segment, useParams()
                            returns an object of the actual data put after /apply/*/}
                            <Route
                                path="/student-profile"
                                element={<StudentProfile/>}
                            ></Route>
                            <Route
                                path="/apply-form/:busID"
                                element={<ApplyForm/>}
                            ></Route>

                            <Route
                                path="/my-applications"
                                element={<CheckBusinessAuthenticated ComponentWithAuth={MyApplications}/>}
                            ></Route>
                            <Route
                                path="*"
                                element={<NotFound/>}
                            ></Route>
                            <Route
                                path="/verification"
                                element={<Verification/>}
                            ></Route>
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword/>}
                            ></Route>
                            <Route
                                path="/admin"
                                element={<CheckAdminAuthenticated ComponentWithAuth={Admin}/>}
                            ></Route>
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        </div>
    );
}

export default App;
