import { useState, useEffect } from "react"
import { collection, addDoc } from 'firebase/firestore';
import { user, db } from "../firebaseInit";
import { query, where, limit, getDocs, updateDoc, doc } from "firebase/firestore";
import { Alert, Collapse, TextField } from '@mui/material';
import "../Styling/Alert.css";
import "../Styling/StudentProfile.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const StudentProfile = () => {
    // variables
    const [studentFirstName, setStudentFirstName] = useState("")
    const [studentLastName, setStudentLastName] = useState("")
    let studentName = studentFirstName.replace(/\s+/g, ' ').trim() + " " + studentLastName.replace(/\s+/g, ' ').trim();
    const [studentBio, setStudentBio] = useState("")
    const [studentLocation, setStudentLocation] = useState("")
    const [studentFirstNamePlaceholder, setStudentFirstNamePlaceholder] = useState("")
    const [studentLastNamePlaceholder, setStudentLastNamePlaceholder] = useState("")
    let studentNamePlaceholder = "";
    const [studentBioPlaceholder, setStudentBioPlaceholder] = useState("")
    const [studentLocationPlaceholder, setStudentLocationPlaceholder] = useState("")
    const [alertSuccess, SetOpenSuccess] = useState(false);
    const [alertWarning, SetOpenWarning] = useState(false);
    const [alertNoChange, SetOpenNoChange] = useState(false);
    const [alertFail, SetOpenFail] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isFocused, setIsFocused] = useState({
        firstName: false, 
        lastName: false,
        bio: false,
        location: false,
    });
    const auth = getAuth();
    const inputSX = {
        marginTop: "15px",
        width: "100%",
        '&:MuiInputBase-notchedOutline':{
            borderRadius: '12px',
            borderColor: '#ccc',
        }
    };

    // handle focus on text area
    const handleFocus = (field) => {
        return () => {
            setIsFocused({...isFocused, [field]: true});
        }
    }
    const handleBlur = (field) => {
        return () => {
            setIsFocused({...isFocused, [field]: false});
        }
    }

    // handle changes in input fields
    const studentFirstNameChange = (event) => {
        setStudentFirstName(event.target.value)
    }
    const studentLastNameChange = (event) => {
        setStudentLastName(event.target.value)
    }
    const studentBioChange = (event) => {
        setStudentBio(event.target.value)
    }
    const studentLocationChange = (event) => {
        setStudentLocation(event.target.value)
    }

    // fetch data from database
    const fetchData = async() => {
        if(user) {
            const querySnapshot = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", user.uid), limit(1)));
            if(querySnapshot.docs.length == 1) {
                studentNamePlaceholder = querySnapshot.docs[0].data().studentName;
                setStudentFirstNamePlaceholder(studentNamePlaceholder.split(" ")[0]);
                setStudentLastNamePlaceholder(studentNamePlaceholder.split(" ")[1]);
                setStudentBioPlaceholder(querySnapshot.docs[0].data().studentBio);
                setStudentLocationPlaceholder(querySnapshot.docs[0].data().studentLocation);
            }
        }
    };

    // call fetchData on page load && onAuthStateChanged
    useEffect(() => {
        onAuthStateChanged(auth, fetchData);
    },[])

    // call fetchData on alertSuccess
    useEffect(() => {
        fetchData();
    }, [alertSuccess]);

    // handle porfile update submission
    const submit = async(event) => {
        event.preventDefault();
        const querySnapshot = await getDocs(query(collection(db, "StudentProfile"), where("uID", "==", user.uid), limit(1)));

        let studentNameData = "";
        if(studentFirstName == "" && studentLastName == "") {
            studentNameData = studentNamePlaceholder;
        } else if(studentFirstName == "") {
            studentNameData = studentFirstNamePlaceholder + " " + studentLastName;
        } else if(studentLastName == "") {
            studentNameData = studentFirstName + " " + studentLastNamePlaceholder;
        } else {
            studentNameData = studentName; 
        }
        // data to be updated
        const data = {
            studentName: studentNameData,
            studentBio: studentBio == "" ? studentBioPlaceholder : studentBio,
            studentLocation: studentLocation == "" ? studentLocationPlaceholder : studentLocation
        }

        // check inputs validations
        if(data.studentName == "" || data.studentBio == "" || data.studentLocation == "") {
            let message = "Please fill out all fields.";
            setWarningMessage(message);
            SetOpenSuccess(false);
            SetOpenFail(false);
            SetOpenWarning(true);
            SetOpenNoChange(false);
        } else if(data.studentName == studentNamePlaceholder && data.studentBio == studentBioPlaceholder && data.studentLocation == studentLocationPlaceholder) {
            let message = "No changes detected.";
            setWarningMessage(message);
            SetOpenSuccess(false);
            SetOpenFail(false);
            SetOpenNoChange(true);
        } else if(querySnapshot.docs.length == 1) {
            await updateDoc(doc(db, "StudentProfile", querySnapshot.docs[0].id), data);
            SetOpenSuccess(true);
            SetOpenFail(false);
            SetOpenWarning(false);
            SetOpenNoChange(false);
        } else {
            console.log("ERROR: UID not found in database.");
            SetOpenFail(true);
            SetOpenSuccess(false);
            SetOpenWarning(false);
            SetOpenNoChange(false);
        }
    }

    return (
        <div className="profile-box-one" >
            <h1 className="student-profile-h1">My Profile</h1>
            <form className="student-profile-update-form" onSubmit={submit}>
                <TextField
                    type="text"
                    label={studentFirstNamePlaceholder ? (
                        isFocused.firstName ? "First Name" : "First Name: " + studentFirstNamePlaceholder
                    ) : (
                        "First Name"
                    )}
                    value={studentFirstName}
                    placeholder={studentFirstNamePlaceholder ? studentFirstNamePlaceholder : "" }
                    onChange={studentFirstNameChange}
                    sx={inputSX}
                    autoComplete="off"
                    onFocus={handleFocus("firstName")}
                    onBlur={handleBlur("firstName")}
                />
                <TextField
                    type="text"
                    label={studentLastNamePlaceholder ? (
                        isFocused.lastName ? "Last Name" : "Last Name: " + studentLastNamePlaceholder
                    ) : (
                        "Last Name"
                    )}
                    value={studentLastName}
                    placeholder={studentLastNamePlaceholder ? studentLastNamePlaceholder : "" }
                    onChange={studentLastNameChange}
                    sx={inputSX}
                    autoComplete="off"
                    onFocus={handleFocus("lastName")}
                    onBlur={handleBlur("lastName")}
                />
                <TextField
                    type="text"
                    label={studentBioPlaceholder ? (
                        isFocused.bio ? "Biography" : "Biography: " + studentBioPlaceholder
                    ) : (
                        "Biography"
                    )}
                    value={studentBio}
                    placeholder={studentBioPlaceholder ? studentBioPlaceholder : "" }
                    multiline
                    rows={5}
                    onChange={studentBioChange}
                    sx={inputSX}
                    InputProps={{
                        classes:{
                            input: isFocused.bio ? 'stu-custom-scrollbar' : 'stu-hide-scrollbar',
                        }
                    }}
                    autoComplete="off"
                    onFocus={handleFocus("bio")}
                    onBlur={handleBlur("bio")}
                />
                <TextField
                    type="text"
                    label={studentLocationPlaceholder ? (
                        isFocused.location ? "Location" : "Location: " + studentLocationPlaceholder
                    ) : (
                        "Location"
                    )}
                    value={studentLocation}
                    placeholder={studentLocationPlaceholder ? studentLocationPlaceholder : "" }
                    onChange={studentLocationChange}
                    sx={inputSX}
                    autoComplete="off"
                    onFocus={handleFocus("location")}
                    onBlur={handleBlur("location")}
                />
                <Collapse className="alert-container" in={alertSuccess}>
                    <Alert className="alert" variant="outlined" severity="success" onClose={() => {SetOpenSuccess(false)}}>Your information has been updated.</Alert>
                </Collapse>
                <Collapse className="alert-container" in={alertWarning}>
                    <Alert className="alert" variant="outlined" severity="warning" onClose={() => {SetOpenWarning(false)}}>{warningMessage}</Alert>
                </Collapse>
                <Collapse className="alert-container" in={alertNoChange}>
                    <Alert className="alert" variant="outlined" severity="warning" onClose={() => {SetOpenNoChange(false)}}>{warningMessage}</Alert>
                </Collapse>
                <Collapse className="alert-container" in={alertFail}>
                    <Alert className="alert" variant="outlined" severity="error" onClose={() => {SetOpenFail(false)}}>Your information has failed to update.</Alert>
                </Collapse>
                <button className="student-profile-update-button" type="submit">Update Info</button>
                
            </form>
        </div>
    )
}

export default StudentProfile