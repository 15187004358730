//this is for students looking to see the apps they sent out
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { query } from "firebase/firestore";
import { collection, where, getDocs } from "firebase/firestore";
import Loading from "./Loading";
import { user, db } from "../firebaseInit";
import { doc, getDoc } from "firebase/firestore";
import "../Styling/Applications.css"
import "../Styling/BusinessProfileCard.css"

//imports for the table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import { AlertTitle } from "@mui/material";

const Applications = () => {
    // variables
    const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const[docData, setDocData] = useState([]);//doc data is array of objects... the objects are the data of the busineses
    const[acceptedOrRejected, setAcceptedOrRejected] = useState([]);
    const[loading, setLoading] = useState(true);
    const[noApps, setNoApps] = useState(false); 
    const[acceptedALert, setAcceptedALert] = useState(false);
    const navigate = useNavigate();
    
    // grab the applications the students applied to and the each business's uid student applied 
    useEffect(() => {
        const verifyAuthAndGetApps = async() => {
            const querySnapshotStudent = await getDocs(query(collection(db, "applications"), where("from", "==", user.uid)));
            if(querySnapshotStudent.empty){
                setNoApps(true)
            } else {
                setNoApps(false)
            }

            //THE ORDER IS IMPORTANT HERE, THE DOC DATA ARRAY MUST BE IN THE SAME ORDER AS THE ACCEPTED OR REJECTED ARRAY
            const toIds = querySnapshotStudent.docs.map(doc => doc.data().to)
            setAcceptedOrRejected(querySnapshotStudent.docs.map(doc => doc.data().status))
            const docDataStandIn = [];
            await Promise.all(
                toIds.map(async(toId) => {
                    const docSnap = await getDoc(doc(db, "BusinessProfile", toId));
                    docDataStandIn.push(docSnap.data())
                })
            )
            setDocData(docDataStandIn);
            setLoading(false)
        }
        verifyAuthAndGetApps();
    }, [])

    // alert student that application accepted if there is any
    useEffect(() => {
        if(acceptedOrRejected.includes("accepted")){
            setAcceptedALert(true);
        }
    }, [acceptedOrRejected]);
    
    // handle link to the explore page
    const handleNavToExplore = () => {
        navigate("/explore-page")
    };

    // handle closing the accepted Alert
    const handleCloseAlert = () => {
        setAcceptedALert(false)
    };

    return ( 
        <div>
            <h1>My Applications</h1>
            <div id="myapplications-page-body">
            { loading ? (
                <Loading/>
                ) : noApps ? (
                    <div className="no-apps-yet">
                        <h2>No Applications Yet!</h2>
                        <p className="text-left">
                            You have not applied to any business yet. Please head to the Explore Page to find businesses to apply to.
                        </p>
                        <button id="explore-button" onClick={handleNavToExplore}>
                            Explore Page
                        </button>
                    </div>
                ) : (
                    <div id="table-container">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Pay</TableCell>
                                        <TableCell align="center">Company Name</TableCell>
                                        <TableCell align="center">Company Description</TableCell>
                                        <TableCell align="center">Company Location</TableCell>
                                        <TableCell align="center">Accepted/Rejected</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {docData.map((doc, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">${doc.price}</TableCell>   
                                                <TableCell align="center">{doc.companyName}</TableCell>
                                                <TableCell align="center">{doc.companyDescription}</TableCell>
                                                <TableCell align="center">{doc.companyLocation}</TableCell>
                                                <TableCell align="center">
                                                    <div className="statusBlock">
                                                        {acceptedOrRejected[index]}
                                                        <Tooltip 
                                                            title={acceptedOrRejected[index] === "pending" ? (
                                                                "This application is still pending"
                                                            ) : (
                                                                acceptedOrRejected[index] === "accepted" ? (
                                                                    "You have been accepted"
                                                                ) : (
                                                                    "You have been rejected"
                                                                )
                                                            )}
                                                            placement="right"
                                                            arrow
                                                        >
                                                            <InfoIcon className="info-icon"/>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {acceptedALert && (
                            <Alert 
                            icon={
                                <CheckIcon 
                                fontSize="inherit" 
                                style={{
                                    marginRight: "16px",
                                    marginLeft: "16px",
                                    marginTop: "8px",
                                }}
                            />}
                            className="accepted-alert"
                            variant="outlined"
                            severity="success"
                            sx={{
                                backgroundColor: isDarkTheme ? "#08601f" : "#a3f7b9", 
                                transition: "all 0.5s ease"
                            }}
                            onClose={handleCloseAlert}
                            >
                                <span className="update-message">
                                    <AlertTitle
                                        className="Email-Reachout-Title"
                                        sx={{
                                            fontWeight: "bold", 
                                            textAlign: "left",
                                            fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.9rem",
                                                md: "1.3rem",
                                            }
                                        }}
                                    >
                                        You Have Been Accepted!
                                    </AlertTitle>
                                    <p className="Email-Reachout-Message">You will receive an email within 48 hours! Please check your email to receive updated information!</p>
                                </span>
                            </Alert>
                        )}
                    </div>
                )
            }
            </div>
        </div>
    ) 
}

export default Applications