//TODO: when put into production, turn on security (change lalow read, write: if false)
import { useState, useEffect } from 'react';
import { collection, addDoc, query, where, getDocs, doc} from 'firebase/firestore';
import { user, db } from "../firebaseInit";
import { updateDoc } from 'firebase/firestore';
import { limit } from 'firebase/firestore';
import { Alert, Collapse, Tooltip } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import "../Styling/Alert.css";
import '../Styling/BusinessProfile.css';
import {Select, InputLabel, MenuItem, FormControl, Box, TextField, InputAdornment} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';


const BusinessProfile = () => {
    // variables
    const [companyName, setCompanyName] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [companyLocation, setCompanyLocation] = useState("")
    const [price, setPrice] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [companyNamePlaceholder, setCompanyNamePlaceholder] = useState(null)
    const [companyDescriptionPlaceholder, setCompanyDescriptionPlaceholder] = useState(null)
    const [companyLocationPlaceholder, setCompanyLocationPlaceholder] = useState(null)
    const [pricePlaceholder, setPricePlaceholder] = useState(null);
    const [alertSuccess, SetOpenSuccess] = useState(false);
    const [alertFail, SetOpenFail] = useState(false);
    const [alertWarning, SetOpenWarning] = useState(false);
    const [priceLimitMessage, SetPriceLimitMessage] = useState("");
    const [priceOverLimit, SetPriceOverLimit] = useState(false);
    const [warningMessage, SetWarningMessage] = useState("");
    const [iconForStatus, setIconForStatus] = useState("");
    const auth = getAuth();
    const businessTypes = [
        "retail",
        "service",
        "manufacturing",
        "wholesale",
        "technology",
        "finance",
        "health care",
        "education",
        "hospitality",
        "real estate",
        "transportation",
        "construction",
        "entertainment",
        "legal",
        "nonprofit",
        "food",
        "other"
    ];
    const inputSX = {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            borderColor: '#ccc',
        },
        width: "100%",
        marginTop: "15px",
        color: "#ccc",
    };
    const [isFocused, setIsFocused] = useState({
        CompanyName: false,
        CompanyDescription: false,
        CompanyLocation: false,
        Price: false,
    });

    // handle focus on input fields (description needs hide scrollbar)
    const handleFocus = (field) => {
        return () => {
            setIsFocused({...isFocused, [field]: true});
        }
    }
    const handleBlur = (field) => {
        return () => {
            setIsFocused({...isFocused, [field]: false});
        }
    }

    // handle changes in input fields
    const companyNameChange = (event) => {
        setCompanyName(event.target.value)
    }
    const companyDescriptionChange = (event) => {
        setCompanyDescription(event.target.value)
    }
    const companyLocationChange = (event) => {
        setCompanyLocation(event.target.value)
    }
    const companyTypeChange = (event) => {
        setBusinessType(event.target.value)
    }
    const priceChange = (event) => {
        setPrice(event.target.value);
    }

    // format price with commas
    const formatPriceWithCommas = (value) => {
        const parts = value.split('.');
    
        // Format the whole number part with commas
        const wholeNumberPart = parts[0].replace(/[^\d]/g, '');
        const formattedWholeNumber = wholeNumberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
        // Limit the decimal part to two digits
        const decimalPart = parts[1] !== undefined ? '.' + parts[1].slice(0, 2) : '';
    
        return formattedWholeNumber + decimalPart;
    };

    // getting the status of the profile and save them in iconForStatus
    const retrieveStatus = async() => {
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));
            if(querySnapshot.docs.length != 0) {
                if(querySnapshot.docs[0].data().status == "pending") {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Pending</h2>
                            <Tooltip title="Profile currently under review by OC Interns!" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    )
                } else if (querySnapshot.docs[0].data().status == "accepted") {
                    setIconForStatus(
                            <div className="statusBlock">
                                <h2>Accepted</h2>
                                <Tooltip title="Profile accepted by OC Interns! Students can now see your profile" placement='right' arrow>
                                    <InfoIcon className="statusIcon"/>
                                </Tooltip>
                            </div>
                    )
                } else if(querySnapshot.docs[0].data().status == "rejected") {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Rejected</h2>
                            <Tooltip title="Profile rejected by OC Interns! Please contact OC-Interns for more information" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    ) 
                } else {
                    setIconForStatus(
                        <div className="statusBlock">
                            <h2>Not Live</h2>
                            <Tooltip title="Profile not live! Please update your profile to be reviewed by OC Interns" placement='right' arrow>
                                <InfoIcon className="statusIcon"/>
                            </Tooltip>
                        </div>
                    )
                }
            }
        }
    }

    // fetching the data from firestore
    // will be the value of the user's previous input, else its empty
    const fetchData = async() => {
        if(user){
            const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));
            querySnapshot.forEach((doc) => {
                setCompanyDescriptionPlaceholder(doc.data().companyDescription);
                setCompanyNamePlaceholder(doc.data().companyName);
                setCompanyLocationPlaceholder(doc.data().companyLocation);
                setPricePlaceholder(doc.data().price);
                setBusinessType(doc.data().businessType);
            });
        }
    }

    // auth state change hits retrieveStatus and fetchData
    useEffect(() => {
        onAuthStateChanged(auth, retrieveStatus);
        onAuthStateChanged(auth, fetchData);
    }, [])

    // fetchData when business updated profile successfully
    useEffect(() => {
        fetchData();
    }, [alertSuccess])

    //send data to firestore/ async await due to
    /*must put the uID in firestore to know which student
    sent an application to which business*/
    const submit = async(event) => {
        event.preventDefault();
        const querySnapshot = await getDocs(query(collection(db, "BusinessProfile"), where("uID", "==", user.uid), limit(1)));

        const data = {
            companyName: companyName === "" ? companyNamePlaceholder : companyName,
            companyDescription: companyDescription === "" ? companyDescriptionPlaceholder : companyDescription,
            companyLocation: companyLocation === "" ? companyLocationPlaceholder : companyLocation,
            price: formatPriceWithCommas(price) === "" ? pricePlaceholder : formatPriceWithCommas(price),
            status: "pending",
            businessType: businessType,
        }
        
        if(data.companyName == "" || data.companyLocation == "" || data.companyDescription == "" || data.price == "" || data.businessType == ""){
            let message = "Please fill out all fields (including your business type) to update/set your profile!";
            SetWarningMessage(message);
            SetOpenSuccess(false);
            SetOpenWarning(true);
            SetPriceOverLimit(false)
            SetOpenFail(false);
        } else if(data.companyName == companyNamePlaceholder && data.companyLocation == companyLocationPlaceholder && data.companyDescription == companyDescriptionPlaceholder && data.price == pricePlaceholder && data.businessType == businessType){
            let message = "No changes detected!";
            SetWarningMessage(message);
            SetOpenSuccess(false);
            SetOpenWarning(true);
            SetPriceOverLimit(false)
            SetOpenFail(false);

        }else if(price >= 50000){
            let message = "Price must be less than $50,000!";
            SetPriceLimitMessage(message);
            SetOpenSuccess(false);
            SetOpenWarning(false);
            SetPriceOverLimit(true);
            SetOpenFail(false);
        } else if(querySnapshot.docs.length == 1){
            await updateDoc(doc(db, "BusinessProfile", querySnapshot.docs[0].id), data);
            retrieveStatus();
            SetOpenSuccess(true);
            SetOpenWarning(false);
            SetPriceOverLimit(false);
            SetOpenFail(false);
        } else {
            //"THIS SHOULD NOT BE HIT, means UID was not sent to firestore upon immediate authentication")
            console.log("Error: UID not sent to firestore upon immediate authentication");
            SetOpenSuccess(false);
            SetOpenWarning(false);
            SetPriceOverLimit(false);
            SetOpenFail(true);
        }
    }

    return (
        <div className="business-profile-box">
            <h1 className="business-profile-h1">My Profile</h1>
            {
                <div>
                    <form onSubmit={submit}>
                        {iconForStatus}
                        <TextField 
                            type="text" 
                            label={ companyNamePlaceholder ? (
                                isFocused.CompanyName ? ("Company Name") : ("Company Name: " + companyNamePlaceholder)
                            ) : (
                                "Company Name"
                            )}
                            value={companyName} 
                            placeholder={companyNamePlaceholder ? companyNamePlaceholder : ""} 
                            onChange={companyNameChange}
                            sx={inputSX}
                            autoComplete='off'
                            onFocus={handleFocus("CompanyName")}
                            onBlur={handleBlur("CompanyName")}
                        />
                        <TextField 
                            type="text" 
                            label={ companyDescriptionPlaceholder ? (
                                isFocused.CompanyDescription ? ("Company Description") : ("Company Description: " + companyDescriptionPlaceholder)
                            ) : (
                                "Company Description"
                            )}
                            value={companyDescription} 
                            placeholder={companyDescriptionPlaceholder ? companyDescriptionPlaceholder : ""} 
                            onChange={companyDescriptionChange}
                            multiline
                            rows={5}
                            sx={inputSX}
                            autoComplete='off'
                            InputProps={{
                                classes:{
                                    input: isFocused.CompanyDescription ? 'bus-custom-scrollbar' : 'bus-hide-scrollbar',
                                }
                            }}
                            onFocus={handleFocus("Description")}
                            onBlur={handleBlur("Description")}
                        />
                        <TextField 
                            type="text" 
                            label={ companyLocationPlaceholder ? (
                                isFocused.CompanyLocation ? ("Company Location (City)") : ("Company Location (City): " + companyLocationPlaceholder)
                            ) : (
                                "Company Location (City)"
                            )}
                            value={companyLocation} 
                            placeholder={companyLocationPlaceholder ? companyLocationPlaceholder : ""}
                            onChange={companyLocationChange}
                            sx={inputSX}
                            autoComplete='off'
                            onFocus={handleFocus("Location")}
                            onBlur={handleBlur("Location")}
                        />
                        <TextField 
                            type="number" 
                            label="Agreed Price"
                            value={price} 
                            placeholder={pricePlaceholder ? pricePlaceholder : ""}
                            onChange={priceChange}
                            sx={inputSX}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            autoComplete='off'
                            onFocus={handleFocus("Price")}
                            onBlur={handleBlur("Price")}
                        />
                        <Box sx={{ minWidth: 120, marginTop: '15px' }} className="business-type-dropdown-container">
                            <FormControl fullWidth>
                                <InputLabel id="businessTypeLabel">Business Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Business Type"
                                    value={businessType}
                                    onChange={companyTypeChange}
                                    sx={{
                                        //curves box border/colors same as others
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '12px',
                                        borderColor: '#ccc', // This is important to avoid text cutting
                                      },
                                     }}
                                >                       
                                    {businessTypes.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        
                        <Collapse className="alert-container" in={alertSuccess}>
                            <Alert className="alert" variant="outlined" severity="success" onClose={() => {SetOpenSuccess(false)}}>Your information is pending an update.</Alert>
                        </Collapse>
                        <Collapse className="alert-container" in={alertWarning}>
                            <Alert className="alert" variant="outlined" severity="warning" onClose={() => {SetOpenWarning(false)}}>{warningMessage}</Alert>
                        </Collapse>
                        <Collapse className="alert-container" in={priceOverLimit}>
                            <Alert className="alert" variant="outlined" severity="warning" onClose={() => {SetPriceOverLimit(false)}}>{priceLimitMessage}</Alert>
                        </Collapse>
                        <Collapse className="alert-container" in={alertFail}>
                            <Alert className="alert" variant="outlined" severity="error" onClose={() => {SetOpenFail(false)}}>Your information has failed to update.</Alert>
                        </Collapse>
                        {businessType === "selected other" && 
                            <input type="text" className="input-field-box" placeholder="Other Business Type" onChange={companyTypeChange} />
                        }
                        <button className="student-profile-button" type="submit">Update Info</button>
                    </form>
                </div>
            }
        </div>
    )
}

export default BusinessProfile
