import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { TextField } from "@mui/material";
import React from 'react'
import "../Styling/ForgotPassword.css";
import { useState } from "react";
import {Alert} from "@mui/material";

const ForgotPassword = () => {
    // variables
    const[email, setEmail] = useState("");
    const[showSucess, setShowSucess] = useState(false);
    const[showError, setShowError] = useState(false);

    // handle resetting the password
    const resetPassword = () => {
        setShowSucess(false);
        setShowError(false);
        const auth = getAuth();
        
        sendPasswordResetEmail(auth, email)
        .then(() => {
            setShowSucess(true);
        })
        .catch((error) => {
            setShowError(true);
            console.error(error)
        });
    }

    // changing the email
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    return(
        <div id="forgot-password-component">
            <div id='forgot-password-parent'>
                <h1 id='reset-account-pass'>Reset Account Password</h1>
                <p id='subheading-password-reset'>Please enter the email address associated with your account.</p>
                <TextField id="outlined-basic" className="email-field" label="Email" value={email} variant="outlined" onChange={handleEmailChange}/>
                <div id="submit-message">
                    {showSucess && <Alert severity={"success"} id='alert'>Email sent successfully</Alert>}
                    {showError && <Alert severity={"error"} id='alert'>Password reset failed</Alert>}
                </div>
                <div><button className='reset-pass-button' onClick={resetPassword}>Reset Password</button></div>
            </div>
        </div>
    )
}

export default ForgotPassword;